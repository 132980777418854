<template>
  <div class="row justify-content-center">
    <div class="col-md-8 col-sm-10 ">
      <Form action="" class="form" autocomplete="off" @submit="handleFinancials">
        <fieldset :disabled="role">
          <div class="form__wrapRadio">

            <label class="form__label form__label_row">
              <input type="checkbox"
                     hidden="hidden"
                     class="form__checkbox"
                     :checked="financialData.register_for_gst"
                     v-model="financialData.register_for_gst"
              >
              <span class="form__checkbox_icon"></span>
              <span class="form__labelTitle">Registered for GST</span>
            </label>

            <label class="form__label form__label_row">
              <span class="form__labelTitle">Installation price (per order)</span>
              <p>
                <Field type="text" class="form__input"
                       name="installation price"
                       v-model="financialData.installation_price"
                       :rules="'required|decimal:2|min_value:0|max_value:999999'"
                />
                <ErrorMessage class="form__error"  name="installation price" />
              </p>
            </label>

            <label class="form__label form__label_row">
              <span class="form__labelTitle">Minimum rate (per hour)</span>
              <p>
                <Field type="text" class="form__input"
                       name="minimum rate"
                       v-model="financialData.min_rate"
                       :rules="'decimal:2|min_value:0|max_value:9999999'"
                />
                <ErrorMessage class="form__error"  name="minimum rate" />
              </p>
            </label>

            <label class="form__label form__label_row">
              <span class="form__labelTitle">Default margin</span>
              <p>
                <Field type="text" class="form__input"
                       name="default margin"
                       :rules="'required|numeric|min_value:0|max_value:999'"
                       v-model="financialData.default_margin"
                />
                  <ErrorMessage class="form__error"  name="default margin" />
              </p>

            </label>

            <label class="form__label form__label_row">
              <span class="form__labelTitle">Minimum margin</span>
              <p>
                <Field type="text" class="form__input"
                       name="minimum margin"
                       :rules="'required|numeric|min_value:0|max_value:999'"
                       v-model="financialData.minimum_margin"
                />
                <ErrorMessage class="form__error"  name="minimum margin" />
              </p>
            </label>

            <label class="form__label form__label_row">
              <span class="form__labelTitle">Estimate expiry age</span>
              <p>
                <Field type="text" class="form__input"
                       name="estimate expiry age"
                       v-model="financialData.estimate_expiry_age"
                       :rules="'numeric|min_value:1|max_value:99'"
                />
                <ErrorMessage class="form__error"  name="estimate expiry age" />
              </p>
            </label>

            <label class="form__label form__label_row">
              <span class="form__labelTitle">Quote expiry age</span>
              <p>
                <Field type="text" class="form__input"
                       name="quote expiry age"
                       v-model="financialData.quote_expiry_age"
                      :rules="'numeric|min_value:1|max_value:99'"
                />
                <ErrorMessage class="form__error"  name="quote expiry age" />
              </p>

            </label>

          </div>
          <button type="submit" class="btn btn_default"><svg-icon name="solid/floppy-disk" /> Update</button>
        </fieldset>
      </Form>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Financials",

    data() {
      return {
        isActive: '',
        price_or_rate: '',
        models: {
          price_or_rate: 'installation_price'
        }
      }
    },

    methods: {
      handleFinancials() {
       /* this.$validator.validate().then(valid => {
          if (valid) {*/
            this.$store.dispatch('financials/postUpdateFinancials',
              {financials: this.financialData}
            );
          /*}
        })*/
      },

      isChecked() {
        if (this.financialData.price_or_rate === 'price') {
          this.financialData.min_rate = '';
          this.isActive = true;
          this.price_or_rate = this.financialData.price_or_rate;
        }
        else if (this.financialData.price_or_rate === 'rate') {
          this.isActive = false;
        }

      }

    },
    watch: {
      'financialData.price_or_rate'() {
        if (this.financialData.price_or_rate === 'installation_price') {
          this.financialData.min_rate = ''
        } else {
          this.financialData.installation_price = ''
        }
      }
    },

    computed: {
      financialData() {
        return this.$store.getters['financials/financials'];
      },

      role() {
        return this.$store.getters['users/role'];
      }
    },

    mounted() {

      setTimeout(() => {
        this.models.price_or_rate = this.financialData.price_or_rate;
      }, 1000);

      return this.$store.dispatch('financials/getFinancial');
    },

    beforeUpdate() {
      this.isChecked();
    }

  }
</script>

<style scoped lang="scss">

  @import "../../assets/scss/utils/vars";

  .form {
    margin-bottom: 2rem;
    &__row {
      width: 100%;
      align-items: center;
      margin-bottom: 15px;
      justify-content: flex-start;

      .form__label {
        margin: 0;
        &:first-child {
          width: 50%;
        }
        &:last-child {
          width: 20%;
        }
        .form__input {
          width: 100%;
        }

        .form__labelTitle {
          width: 100%;
        }
      }
    }

    &__input {
      text-align: center;
    }

    &__label {
      width: 100%;
      margin: 0 0 15px 0;
      p {
        width: 20%;
      }

      .form__labelTitle {
        width: 50%;
      }

      .form__error {
        width: 100%;
        text-align: right;
      }
    }

    &__labelTitle {
    }

  }

</style>
